import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import capturingLead from "../../../assets/images/blog/capturing-lead-min.png"

export default () => 
<div>
    <SEO title={'How GDPR has changed SEO Content Marketing and SEM/PPC'} 
    description="Has GDPR regulation revolutionized the SEO content marketing world? Let's discover it together and see how ICON services can be of great help!"
    canonical={'https://icon-worldwide.com/blog/how-gdpr-changed-seo-content-marketing'} />


    <NewsHeader/>

    <div id="single-news">
        <h1><span>How GDPR has changed</span> SEO Content Marketing and SEM/PPC [and why you should care] </h1>
        <div id="title-underline"></div>
        <h2>Capturing leads in a post-GDPR world demands a highly synchronized customer journey.</h2>

        <div id="single-news-texts">
            <p>After the GDPR cookie bars went live, often in haste, many clients were surprised how much data they were suddenly missing because so many users do not opt in. Websites must offer visitors compelling value in exchange for their data.</p>
            <p>Too many companies still lose time, money and most importantly… “leads” by failing to consider every point of interaction.</p>
            <p>If you are a product sales driven company you are likely already working to adjust your digital marketing operation to continue to bring leads into the sales funnel.</p>
            <p>It is almost impossible to think about digital marketing in solos. Only an integrated approach, consistently monitored and optimized will deliver results. Managing multiple external partners is equally inefficient and ineffective.</p>
            <p>Too many clients invest massive resources into the design and development of a website and then fail to follow through with the minimum requirements for driving traffic. There is a direct correlation between paid and organic traffic.</p>
            <img src={capturingLead} style={{width:'100%', display:'block', margin:'50px auto'}} alt="Maximizing digital ROI after GDPR has changed SEO content marketing" title="ICON, SEO content marketing, Maximizing digital ROI"/>
            <h2>Great content remains the cornerstone of most digital marketing programs.</h2>
            <ul>
                <li>USE THE KEYWORDS IDENTIFIED IN THE SEO AUDIT PHASE TO CRAFT YOUR CONTENT.</li>
                <li>DON'T HESITATE TO CONTINUALLY EDIT AND REVISE PAST CONTENT FROM TIME TO TIME.</li>
                <li>USE EMAIL MARKETING TO REACH YOUR MOST VALUABLE AUDIENCES WHO HAVE AGREED TO RECEIVE INFORMATION FROM YOU ALREADY.</li>
                <li>SOCIAL MEDIA IS YOUR SECONDARY MEANS OF DISTRIBUTION.</li>
                <li>PPC IS THE CRITICAL MINOR INVESTMENT NEEDED TO ENSURE YOUR CONTENT REACHES YOUR EXACT TARGET.</li>
            </ul>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>
        <NewsFooter previous="how-to-improve-digital-marketing-roi" next="iron-deficiency-day-2018"/>
    </div>

    <Footer noScrollbar="true"/>
</div>